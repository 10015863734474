import React, { Component, lazy, Suspense } from 'react'

import Layout from '../../../components/layout'
import * as PropTypes from 'prop-types'
import '../../../lib/react-alice-carousel/scss/alice-carousel.scss'
import PageHeader from '../../pageHeader/PageHeader'
import '../Pages.scss';
import './IndexTemplate.scss'
import BlogCarousel from '../../indexPage/BlogCarousel'
import Reveal from'../../reveal';
import Footer from '../../../layouts/footer'
const Logos = lazy(() => import('../../logos/Logos'));
const Testimonials = lazy(() => import('../../testimonials/Testimonials'));
const CaseStudiesPickerGroup = lazy(() => import('../../case-studies/CaseStudiesPickerGroup'));
const ContactForm = lazy(() => import('../../contactForm/ContactForm'));


class IndexPageTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      referenceIndex: 0,
      referenceShow: true,
    }
   
    this.handleReferenceNextButtonClick = this.handleReferenceNextButtonClick.bind(this)
    this.swiped = this.swiped.bind(this)
  }

  handleReferenceNextButtonClick(index = null) {
    if (index === this.state.referenceIndex) return

    this.setState({
      referenceShow: false,
    })

    setTimeout(() => {
      this.setState((prevState => ({
        referenceIndex: index != null ? index : (prevState.referenceIndex + 1),
        referenceShow: true,
      })))
    }, 300)
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.body.classList.add('home-page')
    }
  }
  swiped(direction) {
    if (direction === 'next') {
      this.setState({
        referenceShow: false,
      })
      setTimeout(() => {
        this.setState((prevState => ({
          referenceIndex: prevState.referenceIndex + 1,
          referenceShow: true,
        })))
      }, 300)
    } else {
      if (this.state.referenceIndex >= 1) {
        this.setState({
          referenceShow: false,
        })
        setTimeout(() => {
          this.setState((prevState => ({
            referenceIndex: prevState.referenceIndex - 1,
            referenceShow: true,
          })))
        }, 300) 
      }
    }
  }

  componentWillUnmount() {

    if (typeof window !== 'undefined') {
      document.body.classList.remove('home-page')
    }
  }

  render() {
    let { data, lang, enableSaidAboutUs } = this.props
    let groupedStudies = data.caseStudies.childMarkdownRemark.frontmatter.case_studies
    
    groupedStudies = Object.values(groupedStudies.reduce((group, item) => {
        const { category } = item;
        group[category] = group[category] ?? [];
        group[category].push(item);
        return group;
    }, {}));
    return (
      <Layout lang={lang} className="home" >
        
        <PageHeader
          seoTitle="Tvorba eshopu a webstránky na mieru | Narative.sk"
          description="Potrebujete eshop na mieru, alebo responzívny web? Ponúkame vám jedinečné a originálne riešenia s dizajnom webu presne podľa vašich predstáv."
          url="https://narative.sk"
          lang={lang}
          title={ data.landingScreen.childMarkdownRemark.frontmatter.title }
          subtitle={ data.landingScreen.childMarkdownRemark.frontmatter.subtitle }
          featuredPost={ data.landingScreen.childMarkdownRemark.frontmatter.featured_post }
          backgroundImage={ data.landingScreen.childMarkdownRemark.frontmatter.background_image }
        />
       
        <div className="page-content">
           <Suspense>
            <Reveal>
              <section className="hp-clients">
                <div className="container">
                    <Logos lang={lang} logos={data.featuredLogos.childMarkdownRemark.frontmatter.logos} />
                </div>
              </section>
            </Reveal>

            <section className="hp-projects">
              <CaseStudiesPickerGroup lang={lang} groups={groupedStudies} />
            </section>

            <Testimonials
              enableSaidAboutUs={enableSaidAboutUs}
              references={data.saidAboutUs.childMarkdownRemark.frontmatter.references}
              paragraph={data.saidAboutUs.childMarkdownRemark.frontmatter.text_paragraph}
            />
          </Suspense>
            <Reveal>
              <BlogCarousel lang={lang} blogs={data.featuredBlogPosts.childMarkdownRemark.frontmatter.blog_posts} />
            </Reveal>
          <Suspense>
            <Reveal>
              <ContactForm />
            </Reveal>
          </Suspense>
            </div>
        <footer>
            <Footer lang={lang} />
        </footer>
      </Layout>
    )
  }
}

IndexPageTemplate.propTypes = { data: PropTypes.any }

export default IndexPageTemplate
