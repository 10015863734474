import React from 'react'
import { TranslatedLink } from '../components/TranslatedLink'
import { Link } from 'gatsby'
import { getTranslatedSlug, getTranslateFunction } from '../utilities'
import TelephoneNumber from '../components/TelephoneNumber'

const Footer = ({ lang, currentPath }) => {
  const t = getTranslateFunction(lang)

  return (
    <div className="container">
      <div className='row'>
        <div className='col-md-6 left mb-4 mb-md-0 ml-auto pr-md-0'>
          <div className="d-grid d-lg-flex">
            <div>
              <TranslatedLink to='/en' title='Narative'><img src='/images/narative_logo_cropped.svg' width="152px" height="46px" alt='Narative' className={'logo'} /></TranslatedLink>
            </div>
            <div>
              <p className={'ml-lg-5 pl-lg-3'}>
                <span>Táto stránka je chránená službou reCAPTCHA a uplatňujú sa na nej  </span>
                <a target={'_blank'} rel="noopener noreferrer" className="link-primary" href='https://policies.google.com/privacy'>Pravidlá ochrany osobných údajov</a> <span>a </span>
                <a target={'_blank'} rel='noopener noreferrer' className="link-primary" href='https://policies.google.com/terms'>Zmluvné podmienky</a> spoločnosti Google.
              </p>
            </div>
          </div>

        </div>
        <div className='col-md-6 right'>
          <ul className='footer-menu'>
            <li>
              <TranslatedLink
                activeStyle={{ color: '#014EFF' }}
                to='/en/our-works/featured'
                title-bak={t('Navigation.ourWork')}>{t('Navigation.ourWork')}</TranslatedLink>
            </li>
            <li>
              <TranslatedLink
                activeStyle={{ color: '#014EFF' }}
                to='/en/services'
                title-bak={t('Navigation.services')}>{t('Navigation.services')}</TranslatedLink>
            </li>
            <li>
              <TranslatedLink
                activeStyle={{ color: '#014EFF' }}
                to='/en/about-us'
                title-bak={t('Navigation.aboutUs')}>{t('Navigation.aboutUs')}</TranslatedLink>
            </li>
            <li>
              <TranslatedLink
                activeStyle={{ color: '#014EFF' }}
                to='/en/blog'
                title-bak={t('Navigation.blog')}>{t('Navigation.blog')}</TranslatedLink>
            </li>
            <li className='flag' style={{ display: lang === 'sk' ? 'none' : '' }}>
              {
                lang === 'sk' ? (
                  <Link to={getTranslatedSlug(currentPath)} title='English'><img src='/images/svg/flag_us.svg' alt='Narative' /></Link>
                ) : (
                  <Link to={getTranslatedSlug(currentPath)} title='English'><img src='/images/svg/flag_sk.svg' alt='Narative' /></Link>
                )
              }
            </li>
          </ul>

          <div className={'footer-fb d-flex justify-content-center justify-content-md-start align-items-center'}>
            <div className={'d-flex socialite-icons'}>
              <a className="d-flex" href='https://www.facebook.com/narativemedia/' target='_blank' rel='noopener noreferrer'>
                <svg viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg>
              </a>
              <a className="d-flex" href='https://www.instagram.com/narative.sk/' target='_blank' rel='noopener noreferrer'>
                <svg svg viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path></svg>
              </a>
            </div>
            <TelephoneNumber />
          </div>

        </div>
      </div >
    </div>
  )
}

export default Footer;
