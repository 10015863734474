import React from 'react'
import '../../lib/react-alice-carousel/scss/alice-carousel.scss'
import AliceCarousel from 'react-alice-carousel';
import Blog from '../blog/Blog'
import { getTranslateFunction } from '../../utilities';
import { TranslatedLink } from '../TranslatedLink'
import { getSlug } from '../../getSlug'
import LinkBtn from '../linkBtn/LinkBtn'
import CancelDragClick from '../cancelDragClick'

const BlogCarosuel = ({ blogs, lang }) => {

  const t = getTranslateFunction(lang)

  return (
    <>
      <section className="hp-blog homepage-blog">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-9 m-auto">
              <h2>{t('Homepage.FeaturedBlogPosts.Title')}</h2>
              <LinkBtn alignment={'center'} className="hp-blog-link">
                <TranslatedLink to="/en/blog">{t('Homepage.FeaturedBlogPosts.AllPosts')}</TranslatedLink>
              </LinkBtn>

            </div>
          </div>
          <div className="homepage-blob-carousel">
            <AliceCarousel
              duration={500}
              mouseTracking
              infinite
              responsive={{
                0: { items: 1 },
                500: { items: 1.5 },
                700: { items: 2 },
                920: { items: 3 },
              }}
              autoPlay={false}
              autoPlayInterval={15 * 1000}
              disableButtonsControls={true}
              items={blogs.map((post, i) => {
                return (
                  <CancelDragClick
                    key={'CancelDragClick' + i}
                    threshold={1}>
                    <div className={'mt-5'}
                      onMouseDown={() => {
                        document.querySelectorAll('.homepage-blob-carousel').forEach((e) => {
                          e.classList.add('is-dragged')
                        })
                      }}
                      onMouseUp={(e) => {
                        document.querySelectorAll('.homepage-blob-carousel.is-dragged').forEach((e) => {
                          e.classList.remove('is-dragged')
                        })
                      }}
                    >
                      <Blog post={post} image={post.image.childImageSharp.fluid} to={getSlug(post.blog_post, 'blog')} />
                    </div>
                  </CancelDragClick>
                )
              })} />
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogCarosuel